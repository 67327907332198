document.onload = (function(d3){
  "use strict";
  const processGraph = function(container, nodesList, edgesList, activityList, dynamicEdges, groups) {
    const _this = this;
    
    _this.nodes = nodesList || [];
    _this.edges = edgesList || [];
    _this.dynamicEdges = dynamicEdges || '';
    _this.activityList = activityList || [];
    _this.groups = groups

    const svg = d3.select(container).append("svg")
      .attr("width", '100%')
      .attr("height", '100%');

    // define arrow markers for graph links
    _this.defs = svg.append('svg:defs');

    _this.svg = svg;
    _this.svgG = svg.append("g").classed(_this.consts.graphClass, true);
    const svgG = _this.svgG;

    _this.paths = svgG.append("g").classed('edge-layer', true).selectAll("g");
    _this.rects = svgG.append("g").classed('node-layer', true).selectAll("g");

    const dragSvg = d3.zoom()
      .scaleExtent([-16, 16])
      .on('zoom', function() {
        _this.svgG.attr('transform', d3.event.transform);
      })
      
    _this.dragSvg = dragSvg;
    _this.svg.call(dragSvg);
  };

  processGraph.prototype.consts =  {
    nodeClass: "node",
    graphClass: "graph",
  };

  processGraph.prototype.initGraph = function(_callback) {
    
    const _this = this;
    const consts = _this.consts;
    
    // Generate Edges
    _this.paths = _this.paths.data(_this.edges, function(d){
      return d;
    });

    const paths = _this.paths;
    const pathG = paths.enter()
      .append('g')
      .classed('edge', true)
      .attr('edge-start', function(d) {
        return _this.activityList[parseInt(d[1], 0)];
      })
      .attr('edge-end', function(d) {
        return _this.activityList[parseInt(d[2], 0)];
      })
      .attr('edge-label', function(d) {
				const len = 4 + (d[3] * 2);
        return d[len];
      })

    pathG.append('marker')
      .attr('id', function(d, i) {
        return `mark-end-arrow-${i}`;
      })
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 4)
      .attr('markerWidth', 10)
      .attr('markerHeight', 10)
      .attr('orient', 'auto')
      .attr('markerUnits', 'userSpaceOnUse')
      .append('path')
      .attr('d', 'M0,-5L10,0L0,5');

    pathG.append("path")
      .style('marker-end', function(d, i) {
        return `url(#mark-end-arrow-${i})`;
      })
      .classed("link", true)
      .attr("d", function(d) {
				const len = d[3] * 2;
				const points = [];
				for (let i=4; i<len + 4; i+=2) {
					points.push(`${parseFloat(d[i])*72} ${parseFloat(d[i+1])*72}`);
				}
				points[0] = `M${points[0]}C${points[0]}`;
				points.splice(1, 1);
        return points.join(',');
      });

    const edgeText = pathG.append('text')
      .text(function(d) {
				const len = 4 + (d[3] * 2);
        return d[len];
      })
      .attr('x', function(d) {
				const len = 5 + (d[3] * 2);
        return ((parseFloat(d[len])*72) - 10);
      })
      .attr('y', function(d) {
				const len = 6 + (d[3] * 2);
        return parseFloat(d[len])*72;
      })
      .attr("data-value", function(d) {
				const len = 4 + (d[3] * 2) + 5;
        return d[len] ? d[len] : '';
      })
      ;
    paths.exit().remove();
    
    // Generate Nodes
    _this.rects = _this.rects.data(_this.nodes, function(d){ 
      return d;
    });

    const newGs= _this.rects.enter()
      .append("g")
      .attr('node-index', function(d) {
        return parseInt(d[1], 0);
      })
      .attr('node-key', function(d) {
        return _this.activityList[parseInt(d[1], 0)];
      })
      .attr('node-label', function(d) {
        return _this.activityList[parseInt(d[1], 0)];
      });

    newGs.classed(consts.nodeClass, true)
      .attr("transform", function(d) {
				const x = 72 * parseFloat(d[2]);
				const y = 72 * parseFloat(d[3]);
				const w = 72 * parseFloat(d[4]);
				const h = 72 * parseFloat(d[5]);
				const xPos = x - w / 2 + 23;
				const yPos = y - h / 2 + 11;
				return `translate(${xPos}, ${yPos+5})`;
			})

    newGs.append("rect")
			.classed('process-rect', true)
			.attr('x', '-27')
			.attr('y', '-20')
			.attr('rx', 18)
			.attr('height', 40)
			.attr('width', function(d) {
				return ((parseFloat(d[4])*72));
			});
		
		const pCircle = newGs.append("g")
			.classed('process-circle', true)
			.attr('transform', "translate(-6, 0)")
		
		pCircle.append('circle')
			.attr('r', 12)
      .filter(d => d[7])
      .attr('stroke', d => _this.groups[d[7]].color);
		pCircle.append('circle')
			.attr('r', 8)
      .filter(d => d[7])
      .attr('stroke', d => _this.groups[d[7]].color);

    const groupG = newGs.filter(d => d[7]).append('g');
    // const rect = groupG.append('rect').attr()
    groupG
    .append('path')
    .attr('class', 'left-arrow')
    .attr('d', 'M0,0 l2,2 l-2,2')
    .attr('expand', d => _this.groups[d[7]].expanded)
    .attr('style', d => `    transform-origin: center; transform-box: fill-box;`)
    .attr('transform', d => `translate(${((parseFloat(d[4])*72)) - 45}, -2) scale(2.5) rotate(${_this.groups[d[7]].expanded ? 0 : 180})`);

    groupG
    .append('path')
    .attr('class', 'left-arrow')
    .attr('d', 'M0,0 l2,2 l-2,2')
    .attr('expand', d => _this.groups[d[7]].expanded)
    .attr('style', d => `    transform-origin: center; transform-box: fill-box;`)
    .attr('transform', d => `translate(${((parseFloat(d[4])*72)) - 39}, -2) scale(2.5) rotate(${_this.groups[d[7]].expanded ? 180 : 0})`);

    groupG
      .append('rect')
      .attr('class', 'group')
      .attr('group-id', d => d[7])
      .attr('width', '10.5px')
      .attr('height', '10px')
      .attr('x', d => ((parseFloat(d[4])*72)) - 46)
      .attr('y', -5)
      .attr('style', d => `fill: transparent; stroke: transparent`);
    groupG
      .append('title')
      .text(d => _this.groups[d[7]].expanded ? 'Collapse' : 'Expand')

		newGs.append("text")
			.classed('node-text', true)
			.attr("x", "12.784609690826528")
			.attr("y", "-7")
			.text(function(d) {
				 const name = _this.activityList[parseInt(d[1], 0)];
				 return name;
			});
		
		newGs.append("text")
			.classed('node-case-count', true)
			.attr("x", "12.784609690826528")
			.attr("y", "10")
			.text(function(d) {
        return d[6];
     });

    _this.rects.exit().remove();

    const svgGBox = _this.svgG.node().getBBox();
    _this.svg.attr('viewBox', `0.00 -10.00 ${svgGBox.width} ${svgGBox.height + 20}`);

    if(_this.dynamicEdges) {
      addEdges(_this.dynamicEdges, _this);
    }

    if (_callback) {
      _callback(_this);
    }
  };

  function addEdges(dynamicEdges, _this) {
    const dynEdgesList = [];
    const dynEdgeData = dynamicEdges.split('\n');
    
    for (let i=0; i<dynEdgeData.length; i++) {
      const regExp = /\"([^)]+)\"/;
      const matches = regExp.exec(dynEdgeData[i]);
      if (matches && matches.length) {
        const edgeStr = matches[1];
        const edgeValue = edgeStr.replaceAll(' ', '_');
        dynEdgeData[i] = dynEdgeData[i].replaceAll(matches[0], edgeValue);
      }
      const lineItem = dynEdgeData[i].split(' ');
      if (dynEdgeData[i]) {
        dynEdgesList.push(lineItem);
      }
    }
    _this.paths = _this.paths.data(dynEdgesList, function(d){
      return d;
    });
    
    var paths = _this.paths;
    var pathBBox = [];
    const pathG = paths.enter()
      .insert('g', ":first-child")
      .classed('edge appended', true)
      .attr('edge-start', function(d) {
        return _this.activityList[parseInt(d[0], 0)];
      })
      .attr('edge-end', function(d) {
        return _this.activityList[parseInt(d[1], 0)];
      })
      .attr('edge-label', function(d) {
        return d[2];
      });

    pathG.append('svg:marker')
      .attr('id', function(d, i) {
        return `mark-end-arrow-${_this.edges.length + i}`;
      })
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 4)
      .attr('markerWidth', 10)
      .attr('markerHeight', 10)
      .attr('orient', 'auto')
      .attr('markerUnits', 'userSpaceOnUse')
      .append('svg:path')
      .attr('d', 'M0,-5L10,0L0,5')
    
    pathG.append("path")
      .style('marker-end', function(d, i) {
        return `url(#mark-end-arrow-${_this.edges.length + i})`;
      })
      .classed("link", true)
      .attr("d", function(d) {
          const nodes = d3.selectAll('.node-layer .node').nodes();
          const source = d3.select('.node-layer .node[node-index="'+d[0]+'"]').node();
          let sourceNode = source.getBBox();
          const sTransform = d3.select(source).attr("transform");
          const sTranslate = sTransform.substring(sTransform.indexOf("(")+1, sTransform.indexOf(")")).split(",");
          sourceNode.x = sourceNode.x + parseFloat(sTranslate[0]) + (sourceNode.width / 2);
          sourceNode.y = sourceNode.y + parseFloat(sTranslate[1]) + (sourceNode.height);

          const target = d3.select('.node-layer .node[node-index="'+d[1]+'"]').node();
          let targetNode = target.getBBox();
          const tTransform = d3.select(target).attr("transform");
          const tTranslate = tTransform.substring(tTransform.indexOf("(")+1, tTransform.indexOf(")")).split(",");
          targetNode.x = targetNode.x + parseFloat(tTranslate[0]) + (targetNode.width / 2);
          targetNode.y = targetNode.y + parseFloat(tTranslate[1]);

          const dx = targetNode.x - sourceNode.x;
          const dy = targetNode.y - sourceNode.y;
          const dr = Math.sqrt((dx * dx) + (dy * dy));
          pathBBox.push({
            target: targetNode,
            source: sourceNode,
            dx: dx,
            dy: dy,
            dr: dr
          });
          const path = "M" + sourceNode.x + "," + sourceNode.y + "A" + dr + "," + dr + " 0 0,1 " + targetNode.x + "," + targetNode.y;
          return path;
      });
    
    const edgeText = pathG.append('text')
      .text(function(d, i) {
        return d[2];
      })
      .attr('x', function(d, i) {
        return getBoundingBoxCenter(pathG._groups[0][i]).x;
      })
      .attr('y', function(d, i) {
        return getBoundingBoxCenter(pathG._groups[0][i]).y;
      })
      ;
  };

  function getBoundingBoxCenter (element) {
		const path = d3.select(element).select('path.link').node();
		const pointsLen = path.getTotalLength();
		return path.getPointAtLength(pointsLen/3);
	}

  processGraph.prototype.zoomed = function() {
    d3.select("." + this.consts.graphClass).attr("transform", "translate(" + d3.event.translate + ") scale(" + d3.event.scale + ")"); 
  };

  window.processGraph = processGraph;

})(window.d3);
